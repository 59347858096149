import {
  GENERATE_UPDATE_STATUS,
  GENERATE_CLEAR_ALL,
} from '../types';

export function generateShipping(request_ids, print_job_id) {
  return function(dispatch, API) {
    dispatch({
      type: GENERATE_UPDATE_STATUS,
      payload: {
        shipping: {
          loading: true,
          message: 'Creating Shipments',
          type: 'success',
          link: null,
        },
      },
    });

    /* trigger create labels and manifest endpoint */
    return API.generate.shipping({ request_ids, print_job_id })
      .then(() => {
        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            shipping: {
              loading: false,
              message: 'Shipments Created',
              type: 'success',
              link: `See Printing page. You'll get an email when it's all ready`,
            },
          },
        });

        return Promise.resolve(true);

        // /* query for shipping data from webhooks every 2 seconds */
        // return dispatch(fetchFileLinks(print_job_id));
      })
      .catch(({ response }) => {
        let error = 'Connection Failed';

        if (response && response.data) {
          error = response.data.message;
        }

        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            shipping: {
              loading: false,
              message: error,
              type: 'error',
              link: null,
            },
          },
        });

        return Promise.reject(error);
      });
  };
}

// function fetchFileLinks(print_job_id) {
//   return function(dispatch, API, getState) {
//     dispatch({
//       type: GENERATE_UPDATE_STATUS,
//       payload: {
//         labels: {
//           loading: true,
//           message: 'Awaiting Shipping Labels',
//           type: 'success',
//           link: null,
//         },
//       },
//     });
//
//     dispatch({
//       type: GENERATE_UPDATE_STATUS,
//       payload: {
//         manifest: {
//           loading: true,
//           message: 'Awaiting Shipping Manifest',
//           type: 'success',
//           link: null,
//         },
//       },
//     });
//
//     let intervalRunCount = 0;
//
//     return new Promise((resolve, reject) => {
//       const linkFetchInterval = setInterval(() => {
//         const {
//           labels,
//           manifest,
//         } = getState().generationSteps;
//
//         API.generate.links({ print_job_id })
//           .then(({ data }) => {
//             if (data.label_file && !labels.link) {
//               dispatch({
//                 type: GENERATE_UPDATE_STATUS,
//                 payload: {
//                   labels: {
//                     loading: false,
//                     message: 'Labels Ready To Download',
//                     type: 'success',
//                     link: data.label_file,
//                     link_type: 'download',
//                   },
//                 },
//               });
//             }
//
//             if (data.manifest_file && !manifest.link) {
//               dispatch({
//                 type: GENERATE_UPDATE_STATUS,
//                 payload: {
//                   manifest: {
//                     loading: false,
//                     message: 'Manifest Ready To Download',
//                     type: 'success',
//                     link: data.manifest_file,
//                     link_type: 'download',
//                   },
//                 },
//               });
//             }
//
//             if (data.label_file && data.manifest_file) {
//               clearInterval(linkFetchInterval);
//               resolve(true);
//             }
//
//             if (intervalRunCount === 30) {
//               /* TODO - handle error? interval running too long? */
//               return;
//             }
//             intervalRunCount++;
//             console.log(`[fetchFileLinks] - fetch interval has run ${intervalRunCount} times`);
//           })
//           .catch(({ response }) => {
//             let error = 'Connection Failed';
//
//             if (response && response.data) {
//               error = response.data.message;
//             }
//
//             dispatch({
//               type: GENERATE_UPDATE_STATUS,
//               payload: {
//                 labels: {
//                   loading: false,
//                   message: error,
//                   type: 'error',
//                   link: null,
//                 },
//                 manifest: {
//                   loading: false,
//                   message: error,
//                   type: 'error',
//                   link: null,
//                 },
//               },
//             });
//
//             reject(error);
//           });
//       }, 5000);
//     });
//   };
// }

export function clearGenerationSteps() {
  return {
    type: GENERATE_CLEAR_ALL,
  };
}
