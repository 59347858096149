import ApiGroup from './ApiGroup';

export default class importBatchAPI extends ApiGroup {
  constructor() {
    super({
      name: 'import_batch',
    });
  }

    oneoff = (data, brand) => (
      this.post({
        endpoint: `/oneoff/${brand}`,
        data,
      })
    );

    raw = (data,  brand) => (
      this.post({
        endpoint: `/raw/${brand}`,
        data,
      })
    );

    normalize = (data) => (
      this.post({
        endpoint: `/normalize`,
        data,
      })
    )

    transactions = (data) => (
      this.post({
        endpoint: `/transactions`,
        data,
      })
    )
}
