import React from 'react';

import { createDevTools } from 'redux-devtools';
import DockMonitor from 'redux-devtools-dock-monitor';

import FilterableLogMonitor from 'redux-devtools-filterable-log-monitor';

const DevTools = (
  process.env.NODE_ENV === 'production'
    ? () => null
    : createDevTools(
      <DockMonitor
        defaultIsVisible={false}
        toggleVisibilityKey='ctrl-h'
        changePositionKey='ctrl-q'
      >
        <FilterableLogMonitor theme='tube' />

      </DockMonitor>
    )
);

export default DevTools;
