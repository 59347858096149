
// General Action Types
export const DESTROY_ALL = 'DESTROY_ALL';
export const SET_LOADING = 'SET_LOADING';
// export const SET_SUBMITTING = 'SET_SUBMITTING';

// Auth/Credential Action Types
export const SET_USER_AUTH = 'SET_USER_AUTH';
export const SET_USER_UNAUTH = 'SET_USER_UNAUTH';
export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
export const CLEAR_USER_CREDENTIALS = 'CLEAR_USER_CREDENTIALS';

// Error Action Types
export const SET_ERROR = 'SET_ERROR';

// Data Action Types
export const SET_PROGRAM = 'SET_PROGRAM';

// Imports Action Types
export const IMPORTS_ADD_STEP = 'IMPORTS_ADD_STEP';
export const IMPORTS_CLEAR_STEPS = 'IMPORTS_CLEAR_STEPS';

// Exports Action Types
export const SET_EXPORT_DATA = 'SET_EXPORT_DATA';

// Search Action Types
export const SEARCH_BY_EVENT = 'SEARCH_BY_EVENT';

// Fulfillment Action Types
export const SET_FULFILLMENT_TABLE = 'SET_FULFILLMENT_TABLE';
export const UPDATE_FULFILLMENT_TABLE_ROW = 'UPDATE_FULFILLMENT_TABLE_ROW';
export const CLEAR_FULFILLMENT_TABLE = 'CLEAR_FULFILLMENT_TABLE';

// Printing Table Action Types
export const SET_PRINTING_TABLE = 'SET_PRINTING_TABLE';
export const CLEAR_PRINTING_TABLE = 'CLEAR_PRINTING_TABLE';

// Banking Action Types
export const SET_BANK_IMPORT = 'SET_BANK_IMPORT';
export const SET_BANKING_TABLE = 'SET_BANKING_TABLE';
export const CLEAR_BANKING_TABLE = 'CLEAR_BANKING_TABLE';

// Generate Action Types
export const GENERATE_UPDATE_STATUS = 'GENERATE_UPDATE_STATUS';
export const GENERATE_CLEAR_ALL = 'GENERATE_CLEAR_ALL';
