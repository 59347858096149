import {
  GENERATE_UPDATE_STATUS,
} from '../types';

export function generateProgramExport(request_ids, print_job_id) {
  console.log('////generateProgramExports requestIDs', request_ids);
  return function(dispatch, API, getState) {
    const { program } = getState();

    dispatch({
      type: GENERATE_UPDATE_STATUS,
      payload: {
        program_export: {
          loading: true,
          message: `Generating ${program} Exports`,
          type: 'success',
          link: null,
          export_type: `${program}`,
        },
      },
    });

    API.generate.programExport({ request_ids, program, print_job_id })
      .then((exports) => {
        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            program_export: {
              loading: false,
              message: `Successfully generated ${program} WEB export`,
              type: 'success',
              data: exports.data,
              export_type: `${program}`,
            },

          },
        });
      })
      .catch(({ response }) => {
        let error = 'Connection Failed';

        if (response && response.data) {
          error = response.data.message;
        }

        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            program_export: {
              loading: false,
              message: error,
              type: 'error',
              link: null,
            },
          },
        });

        return Promise.reject(error);
      });
  };
}


