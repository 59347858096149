import {
  SET_PROGRAM,
} from '../types';

export function setProgram(payload) {
  return {
    type: SET_PROGRAM,
    payload,
  };
}
