import ApiGroup from './ApiGroup';

export default class authAPI extends ApiGroup {
  constructor() {
    super({
      name: 'auth',
    });
  }

    signin = (data) => (
      this.post({
        endpoint: `/signin`,
        data,
      })
    );

    verify = () => (
      this.get('/verify')
    );
}
