import {
  SET_BANK_IMPORT,
  SET_BANKING_TABLE,
  CLEAR_BANKING_TABLE,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

import {
  setBankingTableLoading,
} from './';

export function fetchBankingTable(status) {
  return function(dispatch, API, getState) {
    const {
      program,
      loading: {
        [LOADING_KEY.banking_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setBankingTableLoading(true));
    }

    return API.bank.fetchTransactions({ status, brand: program })
      .then(({ data }) => {
        dispatch({
          type: SET_BANKING_TABLE,
          payload: data.transactions,
        });

        return dispatch(setBankingTableLoading(false));
      })
      .catch(() => {
        dispatch(setBankingTableLoading(false));
        return Promise.reject();
      });
  };
}

export function fetchMatchingTransactions(amount, account_no) {
  return function(dispatch, API, getState) {
    const {
      loading: {
        [LOADING_KEY.banking_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setBankingTableLoading(true));
    }

    return API.bank.fetchMatchingTransactions({ amount, account_no })
      .then(({ data }) => {
        dispatch(setBankingTableLoading(false));
        return data.transactions;
      })
      .catch(() => {
        dispatch(setBankingTableLoading(false));
        return Promise.reject();
      });
  };
}

export function reassignTransactions(data) {
  return function(dispatch, API, getState) {
    const {
      loading: {
        [LOADING_KEY.banking_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setBankingTableLoading(true));
    }

    return API.bank.reassignTransactions(data)
      .then(({ data }) => {
        dispatch(setBankingTableLoading(false));
        // TODO add message
        console.log(data);
      })
      .catch(() => {
        dispatch(setBankingTableLoading(false));
        return Promise.reject();
      });
  };
}

export function cancelTransaction(transaction_id) {
  return function(dispatch, API, getState) {
    const {
      loading: {
        [LOADING_KEY.banking_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setBankingTableLoading(true));
    }

    return API.bank.cancelTransaction({ transaction_id })
      .then(({ data }) => {
        dispatch(setBankingTableLoading(false));
        // TODO add message
        console.log(data);
        return data.message;
      })
      .catch(() => {
        dispatch(setBankingTableLoading(false));
        return Promise.reject();
      });
  };
}

export function clearBankingTable() {
  return {
    type: CLEAR_BANKING_TABLE,
  };
}

export function importBank(data) {
  console.log('importBank(data)', data);
  return function(dispatch, API) {
    dispatch({
      type: SET_BANK_IMPORT,
      payload: {
        importBank: {
          loading: true,
          message: 'Creating request records',
          type: 'loading',
        },
      },
    });

    return API.bank.import(data)
      .then(() => {
        return dispatch({
          type: SET_BANK_IMPORT,
          payload: {
            importBank: {
              loading: false,
              message: 'Request records created... Import Complete!',
              type: 'success',
            },
          },
        });
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch({
          type: SET_BANK_IMPORT,
          payload: {
            importBank: {
              loading: false,
              message: errorMessage,
              type: 'error',
            },
          },
        });
        return Promise.reject(errorMessage);
      });
  };
}
