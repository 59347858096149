/**
 * Loading Keys
 * ------------
 */

export default {
  auth: 'auth',
  fulfillment_table: 'fulfillment_table',
  banking_table: 'banking_table',
  printing_table: 'printing_table',
};
