import ApiGroup from './ApiGroup';

export default class generateAPI extends ApiGroup {
  constructor() {
    super({
      name: 'generate',
    });
  }

    checks = (data) => (
      this.post({
        endpoint: `/checks`,
        data,
      })
    );

    shipping = (data) => (
      this.post({
        endpoint: `/shipping`,
        data,
      })
    );

    links = (data) => (
      this.post({
        endpoint: `/links`,
        data,
      })
    );

    programExport = (data) => (
      this.post({
        endpoint: `/program_export`,
        data,
      })
    );

    bankExport = (data) => (
      this.post({
        endpoint: `/bank_export`,
        data,
      })
    );

    label = (data) => (
      this.post({
        endpoint: `/label`,
        data,
      })
    );
}
