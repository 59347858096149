import ApiGroup from './ApiGroup';

export default class fulfillmentAPI extends ApiGroup {
  constructor() {
    super({
      name: 'fulfillment',
    });
  }

    checks = (data) => (
      this.post({
        endpoint: `/generate_checks`,
        data,
      })
    );

    fetchRequests = (data) => (
      this.post({
        endpoint: `/requests`,
        data,
      })
    );
}
