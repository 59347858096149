import {
  GENERATE_UPDATE_STATUS,
} from '../types';

export function generateChecks(request_ids) {
  return function(dispatch, API) {
    dispatch({
      type: GENERATE_UPDATE_STATUS,
      payload: {
        checks: {
          loading: true,
          message: 'Generating Checks',
          type: 'success',
          link: null,
        },
      },
    });

    return API.generate.checks({ request_ids })
      .then(({ data }) => {
        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            checks: {
              loading: false,
              message: 'Checks Ready To Print',
              type: 'success',
              link: data.checkLink,
              link_title: 'open pdf',
              link_type: 'open',
            },

          },
        });

        return Promise.resolve(data);
      })
      .catch(({ response }) => {
        let error = 'Connection Failed';

        if (response && response.data) {
          error = response.data.message;
        }

        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            checks: {
              loading: false,
              message: error,
              type: 'error',
              link: null,
            },

          },
        });

        return Promise.reject(error);
      });
  };
}
