import {
  GENERATE_UPDATE_STATUS,
} from '../types';

export function generateBankExport(request_ids, print_job_id, program) {
  console.log('////generateBankExports requestIDs', request_ids);
  return function(dispatch, API) {
    dispatch({
      type: GENERATE_UPDATE_STATUS,
      payload: {
        bank_export: {
          loading: true,
          message: `Generating bank Exports`,
          type: 'success',
          link: null,
          export_type: 'Bank',
        },
      },
    });

    API.generate.bankExport({ request_ids, print_job_id, program })
      .then((exports) => {
        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            bank_export: {
              loading: false,
              message: `Successfully generated bank export`,
              type: 'success',
              data: exports.data,
              export_type: 'Bank',
            },
          },
        });
      })
      .catch(({ response }) => {
        let error = 'Connection Failed';

        if (response && response.data) {
          error = response.data.message;
        }

        dispatch({
          type: GENERATE_UPDATE_STATUS,
          payload: {
            bank_export: {
              loading: false,
              message: error,
              type: 'error',
              link: null,
            },
          },
        });

        return Promise.reject(error);
      });
  };
}


