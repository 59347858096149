import {
  SET_BANKING_TABLE,
  CLEAR_BANKING_TABLE,
} from '../types';

const INITIAL_STATE = false;

const INITIAL_ACTION = { type: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_BANKING_TABLE:
      return action.payload;

    case CLEAR_BANKING_TABLE:
      return false;
  }

  return state;
}
