import nap from 'utils/nap';

import {
  SET_FULFILLMENT_TABLE,
  UPDATE_FULFILLMENT_TABLE_ROW,
  CLEAR_FULFILLMENT_TABLE,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

import {
  setFulfillmentTableLoading,
} from './';

export function fetchFulfillmentTable(status) {
  return function(dispatch, API, getState) {
    const {
      program,
      loading: {
        [LOADING_KEY.fulfillment_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setFulfillmentTableLoading(true));
    }


    return nap(500, API.fulfillment.fetchRequests({ status, brand: program }))
      .then(res => {
        const { requests } = res.data;

        dispatch({
          type: SET_FULFILLMENT_TABLE,
          payload: requests,
        });

        return dispatch(setFulfillmentTableLoading(false));
      })
      .catch(({ response }) => {
        let error = 'Unknown Error';

        if (response && response.data) {
          error = response.data.message;
        }

        return Promise.reject(error);
      });
  };
}

export function updateFulfillmentRowSelected(request_id, selected) {
  return {
    type: UPDATE_FULFILLMENT_TABLE_ROW,
    payload: {
      request_id,
      selected,
    },
  };
}

export function clearFulfillmentTable() {
  return {
    type: CLEAR_FULFILLMENT_TABLE,
  };
}


