import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'utils/route_connect';
import appVersion from 'utils/checkAppVersion';

import LOADING_KEY from 'definitions/keys/loading.keys';
import {
  signOut,
  verifyAuth,
} from 'state/actions';

import DataLoading from 'components/DataLoading/DataLoading';

export default function(ResourcePage) {
  class AuthHawk extends Component {
    componentDidMount() {
      this.handleVerification(this.props);
    }

    componentWillReceiveProps(nextProps) {
      this.handleVerification(nextProps, true);
    }

    handleVerification = (props, shouldSignOut = false) => {
      const {
        /* data */
        loading,
        authenticated,
        credentials,
        location: {
          pathname,
        },

        /* actions */
        signOut,
        verifyAuth,
      } = props;

      if (loading) return;

      if (!authenticated) {
        this.redirect(pathname);
        shouldSignOut && signOut();
        return;
      }

      if (!Object.keys(credentials).length) {
        verifyAuth()
          .then(() => {
            appVersion.check();
          })
          .catch(() => {
            this.redirect(pathname);
          });
        return;
      }

      appVersion.check();
    };


    redirect = (fromPath, toPath = '/') => {
      this.props.history.replace({
        pathname: toPath,
        state: {
          from: { pathname: fromPath },
        },
      });
    };


    render() {
      const {
        loading,
        authenticated,
      } = this.props;

      if (loading || !authenticated) {
        return <DataLoading message="Authenticating..." />;
      }

      return <ResourcePage />;
    }
  }

  AuthHawk.propTypes = {
    /* data */
    authenticated: PropTypes.bool.isRequired,
    credentials: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,

    /* actions */
    signOut: PropTypes.func.isRequired,
    verifyAuth: PropTypes.func.isRequired,
  };

  return connect(
    state => ({
      authenticated: state.authenticated,
      credentials: state.credentials,
      loading: state.loading[LOADING_KEY.auth],
    }),
    {
      signOut,
      verifyAuth,
    }
  )(AuthHawk);
}
