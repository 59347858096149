import keyBy from 'lodash/keyBy';
import {
  SET_PRINTING_TABLE,
  CLEAR_PRINTING_TABLE,
} from '../types';

const INITIAL_STATE = false;

const INITIAL_ACTION = { type: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_PRINTING_TABLE:
      return keyBy(action.payload, 'print_job_id');

    case CLEAR_PRINTING_TABLE:
      return false;
  }

  return state;
}
