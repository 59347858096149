import {
  SET_USER_CREDENTIALS,
  CLEAR_USER_CREDENTIALS,
} from '../types';

const INITIAL_STATE = {};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_USER_CREDENTIALS:
      return action.payload;

    case CLEAR_USER_CREDENTIALS:
      return {};
  }

  return state;
}
