import nap from 'utils/nap';

import {
  SET_USER_AUTH,
  DESTROY_ALL,
  SET_USER_CREDENTIALS,
  // CLEAR_USER_CREDENTIALS,
} from '../types';

import JWT from 'utils/JWT';

import {
  setAuthLoading,
} from './';

export function signinAuth(email, password) {
  return function(dispatch, API) {
    dispatch(setAuthLoading(true));

    return nap(1000, API.auth.signin({ email, password }))
      .then(response => {
        JWT.save(response.data.token);

        dispatch({
          type: SET_USER_AUTH,
          payload: true,
        });

        dispatch({
          type: SET_USER_CREDENTIALS,
          payload: response.data.credentials,
        });

        dispatch(setAuthLoading(false));
        return Promise.resolve(true);
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch(setAuthLoading(false));
        return Promise.reject(errorMessage);
      });
  };
}

export function verifyAuth() {
  return function(dispatch, API) {
    dispatch(setAuthLoading(true));

    return nap(1000, API.auth.verify())
      .then(response => {
        dispatch({
          type: SET_USER_AUTH,
          payload: true,
        });

        dispatch({
          type: SET_USER_CREDENTIALS,
          payload: response.data.credentials,
        });

        dispatch(setAuthLoading(false));
        return Promise.resolve(true);
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch(setAuthLoading(false));
        return Promise.reject(errorMessage);
      });
  };
}

export function signOut() {
  JWT.destroy();
  return {
    type: DESTROY_ALL,
  };
}
