import {
  SEARCH_BY_EVENT,
} from '../types';

const INITIAL_STATE = {};
const INITIAL_ACTION = { type: '', payload: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SEARCH_BY_EVENT:
      return {
        ...state,
        ...action.payload,
      };
  }

  return state;
}
