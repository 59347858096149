import ApiGroup from './ApiGroup';

export default class bankAPI extends ApiGroup {
  constructor() {
    super({
      name: 'bank',
    });
  }

    fetchTransactions = (data) => (
      this.post({
        endpoint: `/transactions`,
        data,
      })
    );

    fetchMatchingTransactions = (data) => (
      this.post({
        endpoint: `/matching_transactions`,
        data,
      })
    );

    reassignTransactions = (data) => (
      this.post({
        endpoint: `/reassign_transactions`,
        data,
      })
    );

    cancelTransaction = (data) => (
      this.post({
        endpoint: `/cancel_transaction`,
        data,
      })
    );

    import = (data) => (
      this.post({
        endpoint: `/import`,
        data,
      })
    )
}
