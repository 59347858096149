import ApiGroup from './ApiGroup';

export default class exports extends ApiGroup {
  constructor() {
    super({
      name: 'exports',
    });
  }

    formatCSV = (data) => (
      this.post({
        endpoint: `/csv`,
        data,
      })
    );
}
