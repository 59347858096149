import ApiGroup from './ApiGroup';

export default class requestEditAPI extends ApiGroup {
  constructor() {
    super({
      name: 'request_edit',
    });
  }

    pluck = (data) => (
      this.post({
        endpoint: `/pluck`,
        data,
      })
    );

    update = (data) => (
      this.post({
        endpoint: `/update`,
        data,
      })
    );
}
