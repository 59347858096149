import {
  SET_LOADING,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

const INITIAL_STATE = {
  [LOADING_KEY.auth]: false,
  [LOADING_KEY.fulfillment_table]: false,
  [LOADING_KEY.export]: null,
  [LOADING_KEY.bank_export]: null,
  [LOADING_KEY.printing_table]: false,
};

const INITIAL_ACTION = { type: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
