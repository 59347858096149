import {
  SET_LOADING,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

export function setAuthLoading(isLoading) {
  return setDataLoading(LOADING_KEY.auth, isLoading);
}

export function setFulfillmentTableLoading(isLoading) {
  return setDataLoading(LOADING_KEY.fulfillment_table, isLoading);
}

export function setBankingTableLoading(isLoading) {
  return setDataLoading(LOADING_KEY.banking_table, isLoading);
}

export function setPrintingTableLoading(isLoading) {
  return setDataLoading(LOADING_KEY.printing_table, isLoading);
}

function setDataLoading(key, isLoading) {
  return {
    type: SET_LOADING,
    payload: {
      [key]: isLoading,
    },
  };
}
