import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import AuthHawk from 'HOC/AuthHawk';

import {
  Signin,
  Admin,
  Error,
} from './lazy_routes';

export default (
  <Switch>

    <Route
      exact
      path="/"
      component={Signin}
    />

    <Route
      path="/admin"
      component={AuthHawk(Admin)}
    />

    <Route component={Error} />

  </Switch>
);
