import auth from './auth.api';
import import_batch from './import_batch.api';
import bank from './bank.api';
import generate from './generate.api';
import fulfillment from './fulfillment.api';
import printing from './printing.api';
import request_edit from './request_edit.api';
import exports from './exports.api';

const API = (() => {
  const groups = {};

  const apiGroups = [
    auth,
    import_batch,
    bank,
    generate,
    fulfillment,
    printing,
    request_edit,
    exports,
  ];

  apiGroups.map(api => {
    const apiGroup = new api();
    const apiGroupName = apiGroup.name;
    if (!apiGroupName) {
      throw new Error('Api apiGroup missing required "name" property');
    }
    groups[apiGroupName] = apiGroup;
  });

  return groups;
})();

export default API;
