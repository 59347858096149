
export function pluckRequestEdit(request_id) {
  return function(dispatch, API, getState) {
    const loading = getState().loading.edit;
    if (loading) return;

    return API.request_edit.pluck({ request_id })
      .then(({ data }) => {
        return Promise.resolve(data.data);
      })
      .catch(response => {
        console.log(response);
        return Promise.reject();
      });
  };
}

export function submitRequestEdit(requestData) {
  return function(dispatch, API) {
    return API.request_edit.update({ requestData })
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}
