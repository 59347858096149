import 'core-js';
import 'es6-shim';
import 'es5-shim';
import 'babel-regenerator-runtime';
import './assets/toImport';
import './browser-support.html';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-table/react-table.css';
import { ThroughProvider } from 'react-through';
import bugsnagReact from '@bugsnag/plugin-react';
import bugsnagClient from './utils/bugsnag_client';

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');
const rootElement = document.getElementById('root');

if (module.hot) {
  module.hot.accept('core/Root', () => {
    setTimeout(render, 0);
  });
}

render();

function render() {
  const Root = require('core/Root').default;
  ReactDOM.render(
    <ThroughProvider>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </ThroughProvider>,
    rootElement
  );
}
