import {
  SET_PROGRAM,
} from '../types';

const INITIAL_STATE = 'Ford';

const INITIAL_ACTION = { type: '', payload: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_PROGRAM:
      return action.payload;
  }

  return state;
}
