import {
  IMPORTS_ADD_STEP,
  IMPORTS_CLEAR_STEPS,
} from '../types';

const INITIAL_STATE = {
  importRaw: {
    loading: null,
    message: '',
    type: '',
  },
  normalizeData: {
    loading: null,
    message: '',
    type: '',
  },
  createTransactions: {
    loading: null,
    message: '',
    type: '',
  },
};

const INITIAL_ACTION = { type: '', payload: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case IMPORTS_ADD_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case IMPORTS_CLEAR_STEPS:
      return {
        ...INITIAL_STATE,
      };
  }

  return state;
}
