import {
  GENERATE_UPDATE_STATUS,
  GENERATE_CLEAR_ALL,
} from '../types';

const INITIAL_STATE = {
  checks: {
    loading: null,
    message: '',
    type: '',
    link_type: 'open',
    link: null,
  },
  shipping: {
    loading: null,
    message: '',
    type: '',
    link: null,
  },
  labels: {
    loading: null,
    message: '',
    type: '',
    link_type: 'download',
    link: null,
  },
  manifest: {
    loading: null,
    message: '',
    type: '',
    link_type: 'download',
    link: null,
  },
  program_export: {
    loading: null,
    message: '',
    type: '',
    link: null,
    link_type: 'download',
    export_tpe: '',
  },
  bank_export: {
    loading: null,
    message: '',
    type: '',
    link: null,
    link_type: 'download',
    export_tpe: '',
  },

};
const INITIAL_ACTION = { type: '', payload: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case GENERATE_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };

    case GENERATE_CLEAR_ALL:
      return INITIAL_STATE;
  }

  return state;
}
