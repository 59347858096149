import keyBy from 'lodash/keyBy';
import {
  SET_FULFILLMENT_TABLE,
  UPDATE_FULFILLMENT_TABLE_ROW,
  CLEAR_FULFILLMENT_TABLE,
} from '../types';

const INITIAL_STATE = false;

const INITIAL_ACTION = { type: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_FULFILLMENT_TABLE:
      return keyBy(action.payload, 'request_id');

    case UPDATE_FULFILLMENT_TABLE_ROW:
      return {
        ...state,
        [action.payload.request_id]: {
          ...state[action.payload.request_id],
          ...action.payload,
        },
      };

    case CLEAR_FULFILLMENT_TABLE:
      return false;
  }

  return state;
}
