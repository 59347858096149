import { combineReducers } from 'redux';
import { DESTROY_ALL } from '../types';

import loading from './loading.reducer';
import error from './error.reducer';
import authenticated from './auth.reducer';
import credentials from './credentials.reducer';
import program from './program.reducer';
import importSteps from './imports.reducer';
import searchResults from './search.reducer';
import fulfillmentTable from './fulfillment.reducer';
import printingTable from './printing.reducer';
import bankingTable from './banking.reducer';
import generationSteps from './generate.reducer';

const appReducer =  combineReducers({
  loading,
  error,
  authenticated,
  credentials,
  program,
  importSteps,
  searchResults,
  fulfillmentTable,
  printingTable,
  bankingTable,
  generationSteps,
});

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === DESTROY_ALL) {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
