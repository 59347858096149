import nap from 'utils/nap';

import {
  SET_PRINTING_TABLE,
  CLEAR_PRINTING_TABLE,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

import {
  setPrintingTableLoading,
} from './';

export function fetchPrintingTable() {
  return function(dispatch, API, getState) {
    const {
      program,
      loading: {
        [LOADING_KEY.printing_table]: loading,
      },
    } = getState();

    if (!loading) {
      dispatch(setPrintingTableLoading(true));
    }


    return nap(500, API.printing.forProgram(program))
      .then(res => {
        dispatch({
          type: SET_PRINTING_TABLE,
          payload: res.data,
        });

        return dispatch(setPrintingTableLoading(false));
      })
      .catch(({ response }) => {
        let error = 'Unknown Error';

        if (response && response.data) {
          error = response.data.message;
        }

        return Promise.reject(error);
      });
  };
}

export function clearPrintingTable() {
  return {
    type: CLEAR_PRINTING_TABLE,
  };
}


