import React, { Component } from 'react';
import Routes from './routes';

class Main extends Component {
  render() {
    return (
      <div>
        { Routes }

      </div>
    );
  }
}

export default Main;
