import {
  SEARCH_BY_EVENT,
} from '../types';

export function searchByEvent(eventID) {
  console.log('searchByEvent eventID', eventID);
  return function(dispatch) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const searchResults = [
          {
            event_id: '12345',
            transaction_id: '345677776',
            account_no: '00000000',
            type: 'deposit',
            check_no: 'zzzzz',
            amount: '281.00',
            check_printed: '2019-05-16',
            transaction_date: '2019-05-16',
            reconciled: '2019-05-16',
            cancelled: null,
          }, {
            event_id: '12345',
            transaction_id: '23448794',
            account_no: '00000000',
            type: 'deposit',
            check_no: 'xxxxxx',
            amount: '100.00',
            check_printed: '2019-05-12',
            transaction_date: '2019-05-16',
            reconciled: null,
            cancelled: null,
          },
          {
            event_id: '12345',
            transaction_id: '34567345576',
            account_no: '00000000',
            type: 'deposit',
            check_no: 'zzzzz',
            amount: '281.00',
            check_printed: null,
            transaction_date: null,
            reconciled: null,
            cancelled: null,
          }, {
            event_id: '12345',
            transaction_id: '656546',
            account_no: '1111111',
            type: 'deposit',
            check_no: 'yyyyy',
            amount: '110.00',
            check_printed: null,
            transaction_date: null,
            reconciled: null,
            cancelled: null,
          }, {
            event_id: '12345',
            transaction_id: '45645645',
            account_no: '00000000',
            type: 'deposit',
            check_no: 'rrrrr',
            amount: '560.00',
            check_printed: null,
            transaction_date: null,
            reconciled: null,
            cancelled: null,
          },
        ];

        dispatch({
          type: SEARCH_BY_EVENT,
          payload: searchResults,
        });

        return resolve(true);
      }, 2000);
    });
  };
}
