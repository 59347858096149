import {
  IMPORTS_ADD_STEP,
  IMPORTS_CLEAR_STEPS,
} from '../types';


export function oneoff(requestData, brand) {
  return function(dispatch, API) {
    return API.import_batch.oneoff({ form: requestData }, brand)
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function importRaw(form, brand) {
  return function(dispatch, API) {
    dispatch({
      type: IMPORTS_ADD_STEP,
      payload: {
        importRaw: {
          loading: true,
          message: 'Importing file',
          type: 'loading',
        },
      },
    });
    return API.import_batch.raw(form, brand)
      .then(batch => {
        dispatch({
          type: IMPORTS_ADD_STEP,

          payload: {
            importRaw: {
              loading: false,
              message: 'File successfully imported',
              type: 'success',
            },
          },
        });

        dispatch(normalizeData({ batchId: batch.data.batchId }));
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch({
          type: IMPORTS_ADD_STEP,
          payload: {
            importRaw: {
              loading: false,
              message: errorMessage,
              type: 'error',
            },
          },
        });
        return Promise.reject(errorMessage);
      });
  };
}

export function normalizeData(data) {
  return function(dispatch, API) {
    dispatch({
      type: IMPORTS_ADD_STEP,
      payload: {
        normalizeData: {
          loading: true,
          message: 'Creating request records',
          type: 'loading',
        },
      },
    });

    return API.import_batch.normalize(data)
      .then(() => {
        dispatch({
          type: IMPORTS_ADD_STEP,
          payload: {
            normalizeData: {
              loading: false,
              message: 'Request records created... Import Complete!',
              type: 'success',
            },
          },
        });
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch({
          type: IMPORTS_ADD_STEP,
          payload: {
            normalizeData: {
              loading: false,
              message: errorMessage,
              type: 'error',
            },
          },
        });
        return Promise.reject(errorMessage);
      });
  };
}

export function createTransactions(data) {
  return function(dispatch, API) {
    dispatch({
      type: IMPORTS_ADD_STEP,
      payload: {
        createTransactions: {
          loading: true,
          message: 'Preparing to create transactions',
          type: 'loading',
        },
      },
    });

    return API.import_batch.transactions(data)
      .then(() => {
        dispatch({
          type: IMPORTS_ADD_STEP,
          payload: {
            createTransactions: {
              loading: false,
              message: 'Transactions created',
              type: 'success',
            },
          },
        });
      })
      .catch((error) => {
        let errorMessage = 'Oops! An unexpected error occurred. Please contact the development team :)';
        // TODO : set message from API response
        if (error.response) {
          errorMessage = error.response.data.message;
        }
        dispatch({
          type: IMPORTS_ADD_STEP,
          payload: {
            createTransactions: {
              loading: false,
              message: 'errorMessage',
              type: 'error',
            },
          },
        });
        return Promise.reject(errorMessage);
      });
  };
}

export function clearImportSteps() {
  return function(dispatch) {
    dispatch({
      type: IMPORTS_CLEAR_STEPS,
    });
  };
}

