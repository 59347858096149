import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export const Signin = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Signin/Signin.container'),
});

export const Admin = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Admin/Admin.container'),
});

export const Error = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Error/Error.container'),
});
