import ApiGroup from './ApiGroup';

export default class fulfillmentAPI extends ApiGroup {
  constructor() {
    super({
      name: 'printing',
    });
  }

  forProgram = (program) => (
    this.get(`/for/${program}`)
  );
}
